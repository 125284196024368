<template>
  <div class="dynamic">
    <load-list :interFace="interFace">
      <template v-slot:list="props">
        <div
          v-for="(item, index) in props.list"
          :key="index"
          class="list"
          @click="toArticleDetail(item.id)"
        >
          <div class="border">
            <div class="title">{{ item.title }}</div>
            <!-- 视频 -->
            <div v-if="item.tpe == 4">
              <Cyberplayer :video_Msg="item.video_path"></Cyberplayer>
            </div>
            <!-- 图片 -->
            <div
              v-if="item.tpe == 2 && item.images.length > 0"
              class="list-img"
            >
              <img
                :src="$IMG_PATH + pitem"
                alt=""
                v-for="(pitem, index) in item.images"
                :key="index"
              />
            </div>
            <!-- 图集 -->
            <div
              v-if="item.tpe == 3 && item.images.length > 0"
              class="list-img"
            >
              <img
                :src="$IMG_PATH + pitem"
                alt=""
                v-for="(pitem, index) in item.images"
                :key="index"
              />
            </div>
            <!-- 文字 -->
            <div
              v-if="item.tpe == 1 && item.brief.length > 0"
              class="list-text"
            >
              {{ item.brief }}
            </div>
            <div class="foot">
              <!-- <img class="item-foot-1" src="@/assets/images/address.png" alt="" /> -->
              <!-- <span class="item-foot-1 iconfont icon-dibiao1"></span> -->
              <!-- <span class="item-foot-2 ml-20">{{ item.area_name }}</span> -->
              <span class="item-foot-3">{{ item.add_time }}</span>
              <span class="item-foot-4 ml-60 iconfont icon-check-line"></span>
              <span class="item-foot-5 ml-10">{{ item.click_count }}</span>
            </div>
          </div>
        </div>
      </template>
    </load-list>
  </div>
</template>

<script>
import Cyberplayer from "../../../components/cyberplayer/index";
import LoadList from "@/components/SearchLoadList";
import { getInform } from "../api";
export default {
  name: "dynamic",
  components: {
    Cyberplayer,
    LoadList,
  },
  data() {
    return {
      interFace: {
        api: getInform,
        params: {
          cid: this.$route.params.cid,
          year:[28,29,30].includes(Number(this.$route.params.cid)) ? window.localStorage.getItem('year1') : [31,32,33,37].includes(Number(this.$route.params.cid)) ? window.localStorage.getItem('year2') : ''
        },
      },
    };
  },
  created() {
    console.log(this.interFace.params,this.$route.params.cid)
    let { cid, name } = this.$route.params;
    document.title = name;
  },
  methods: {
    toArticleDetail(id) {
      this.$router.push({
        name: "articleDetail",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.dynamic {
  width: 100%;
  padding: 0.24rem 0.2rem;
  overflow: hidden;
  .list {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0.1rem;
    // border-bottom: 0.02rem solid rgba(153, 153, 153,.3);
    margin-top: 0.2rem;
    .border {
      padding: 0.24rem 0.26rem;
      .title {
        font-size: 0.32rem;
        color: #333;
        letter-spacing: 0.02rem;
        // font-weight: 600;
        margin-bottom: 0.15rem;
        max-height: 1rem;
        line-height: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .list-text {
        width: 100%;
        max-height: 1.5rem;
        line-height: 0.5rem;
        font-size: 0.24rem;
        color: #8b8b8b;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .list-img {
        width: 100%;
        height: 1.3rem;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        img {
          width: 32%;
          margin: 0 0.14rem 0 0;
        }
      }
      .foot {
        color: #8b8b8b;
        font-size: 0.26rem;
        margin-top: 0.3rem;
        display: flex;
        line-height: 120%;
        .item-foot-1 {
          width: 0.17rem;
          height: 0.2rem;
        }
        .item-foot-2 {
          display: inline-block;
          min-width: 1.4rem;
        }
        .item-foot-4 {
          width: 0.27rem;
          height: 0.15rem;
        }
        .ml-20 {
          margin-left: 0.2rem;
        }
        .ml-30 {
          margin-left: 0.3rem;
        }
        .ml-60 {
          margin-left: 0.6rem;
        }
        .ml-10 {
          margin-left: 0.1rem;
        }
      }
    }
  }
  div.list:first-child {
    border-radius: 0.14rem 0.14rem 0 0;
  }
  div.list:last-child {
    border-radius: 0 0 0.14rem 0.14rem;
  }
}
</style>